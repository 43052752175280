<template>
  <b-card
    tag="article"
    class="program__card student"
    @click="$router.push(`/program-user/${program._id}`)"
  >
    <b-img class="program__image" :src="programImage" alt="Fluid image"></b-img>
    <div class="card-bottom">
    <b-card-text class="program__info">
      <div class="program__title">
        {{ program.name || $t('placeholders.newProgram') }}
      </div>
      <div
        :class="[program.description ? 'body-medium' : 'body-small']"
        class="program__description"
      >
        {{ program.description || $t('placeholders.noDescription') }}
      </div>
    </b-card-text>
      <LmsProgressBarSmall
        v-if="program.max_score !== 0"
        :maxScore="program.max_score"
        :score="program.statistic.score"
        :progressStatus="program.statistic.status"
      />
  </div>
  </b-card>
</template>

<script>
import { doing, done, notStarted } from '@/utils/constants';
import deviceMixin from '@/mixins/device.mixin';
import LmsProgressBarSmall from '@/components/LmsProgressBarSmall.vue';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'ProgramCardStudent',
  components: { LmsProgressBarSmall },
  mixins: [deviceMixin],
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      if (this.program.statistic.status === ActivityStatuses.done) {
        return done;
      }
      if (this.program.statistic.status === ActivityStatuses.doing) {
        return doing;
      }
      return notStarted;
    },
    programImage() {
      let image = require('@/assets/images/png/program-thumbnail.png');
      if (this.program.meta?.imageHighres) {
        image = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? this.program.meta.imageHighres
            : this.$device.isTablet
              ? this.program.meta.imageMidres
              : this.program.meta.imageLowres
        }`;
      }
      return image;
    },
  },
};
</script>
