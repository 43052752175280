<template>
  <div class="progress-small">
    <div class="progress-small__top">
      <div class="lms-progress-bar"
      :class="{
          'lms-progress-bar--doing': progressStatus === ActivityStatuses.doing,
          'lms-progress-bar--done': progressStatus === ActivityStatuses.done,
          'lms-progress-bar--not-started': progressStatus === ActivityStatuses.notStarted,
        }"
      >
        <b-progress :value="percent" :max="100"></b-progress>
      </div>
    </div>
    <div class="progress-small__bottom">
      <div class="percent">
        <span class="title">{{`${$t('label.completedProgress')}:`}}&nbsp;</span>
        <span class="counter">{{percent}}%</span>
      </div>
      <div class="score">
        <span class="title">{{`${$t('label.points')}:`}}&nbsp;</span>
        <span class="counter">{{`${score} / ${maxScore} `}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'LmsProgressBarLarge',
  props: {
    maxScore: {
      type: Number,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    progressStatus: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    ActivityStatuses,
  }),
  computed: {
    percent() {
      return Math.round((this.score / this.maxScore) * 100) || 0;
    },
    status() {
      if (this.progressStatus === ActivityStatuses.doing) {
        return this.$t('label.inProgress');
      }
      if (this.progressStatus === ActivityStatuses.done) {
        return this.$t('label.passed');
      }
      return this.$t('label.notStarted');
    },
  },
};
</script>
